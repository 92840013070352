import { useAuth0 } from '@auth0/auth0-react';
import { useLocalStorage } from 'usehooks-ts';

import { config } from '@arrive/lib/auth0';

export function useLogout() {
  const { logout: auth0Logout } = useAuth0();
  const [, setAccessToken] = useLocalStorage<string | undefined>('access_token', undefined);

  const logout = () => {
    setAccessToken(undefined);
    auth0Logout({ returnTo: config.redirectUri });
  };

  return logout;
}
