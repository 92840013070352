import Role from './roles.constants';

/**
 * From an initial state of roles with false values, creates a new map with the current roles of the user that came from Auth0.
 *
 * @param initialState an immutable default map of roles
 * @param userRoles a list of user's roles configured in Auth0
 * @return a new Map with the granted roles
 */
export const grantRoles = (
  initialState: Map<Role, boolean>,
  userRoles: Role[]
): Map<Role, boolean> => {
  const grantedMap = new Map<Role, boolean>(initialState);
  Array.from(grantedMap.keys()).forEach((role: Role) => {
    const hasRole = userRoles.some((userRole) => userRole === role);
    grantedMap.set(role, hasRole);
  });
  return grantedMap;
};

/**
 * Challenges the requested roles with the current user's roles.
 * Note: if user has a granted role of Admin everything will be true
 *
 * @param grantedRoles A map with the current user roles
 * @param rolesToValidate An array of roles required to access some function
 * @return true if matches the challenge, false otherwise
 */
export const checkRole = (grantedRoles: Map<Role, boolean>, rolesToValidate: Role[]): boolean => {
  // If user is an ADMIN will be authorized for everything
  const isAdmin = grantedRoles.get(Role.ADMIN);
  return (
    isAdmin ||
    rolesToValidate.find((roleToValidate) => grantedRoles.get(roleToValidate) === true) !==
      undefined
  );
};
