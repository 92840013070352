import { Loader } from '@arrive/components/loader';
import { useAuth0 } from '@auth0/auth0-react';
import { ROLE_SCHEMA, Role, RolesConstants } from '@arrive/auth';
import { PropsWithChildren } from 'react';

export function AuthenticationProvider({ children }: Readonly<PropsWithChildren<unknown>>) {
  const { isAuthenticated, isLoading, error, loginWithRedirect, user } = useAuth0();

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return (
      <div>
        <div>Something went wrong.</div>
        <div>{error}</div>
      </div>
    );
  }

  if (!isAuthenticated || !user) {
    loginWithRedirect({ redirectUri: window.location.href });
    return null;
  }

  if (!user[ROLE_SCHEMA].some((val: Role) => RolesConstants.includes(val))) {
    window.location.href = 'https://arriveoutdoors.com';
    return <>Not allowed</>;
  }

  return <>{children}</>;
}
