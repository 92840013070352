import { Variables } from 'relay-runtime';

const endpoint = process.env.REACT_APP_WMS_GRAPHQL_ENDPOINT;

async function fetchGraphQL(query: string, variables: Variables) {
  if (!endpoint) throw new Error('Endpoint is not configured');
  const accessToken = localStorage.getItem('access_token')?.replaceAll('"', '');
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken
    },
    body: JSON.stringify({
      query,
      variables
    })
  });

  return await response.json();
}

export default fetchGraphQL;
