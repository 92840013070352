import * as React from 'react';
import { FC, Suspense, useContext, useState } from 'react';
import {
  AccountCircle as AccountCircleIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  DarkMode as DarkModeIcon,
  LightMode as LightModeIcon,
  Logout as LogoutIcon,
  Menu as MenuIcon
} from '@mui/icons-material';
import { getMenuRoutes, routesToExclude } from '@arrive/routes';

import { Link, Outlet, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useLogout } from '@arrive/hooks/useLogout';

import { Loader } from '@arrive/components/loader';
import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Box,
  CssBaseline,
  CSSObject,
  Divider,
  Drawer as MuiDrawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  Theme,
  Toolbar,
  Typography,
  useTheme
} from '@mui/material';
import ColorModeContext from '@arrive/contexts/ColorModeContext';
import { Route } from '@arrive/routes/layout.routes';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('md')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
);

const DrawerMenuLayout: FC = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const logout = useLogout();
  const location = useLocation();

  const [desktopExpanded, setdesktopExpanded] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { isAuthenticated, user } = useAuth0();
  const [anchorMenuAppBar, setAnchorMenuAppBar] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorMenuAppBar(event.currentTarget);
  };

  const handleDesktopDrawerToggle = () => {
    setdesktopExpanded(!desktopExpanded);
  };

  const handleClose = () => {
    setAnchorMenuAppBar(null);
  };

  const menuRoutes = getMenuRoutes(routesToExclude);

  const drawer = (
    <div>
      <Toolbar>
        <Box
          sx={{
            display: { md: desktopExpanded ? 'block' : 'none' }
          }}>
          <Link to={'/'}>
            <img
              src={
                theme.palette.mode === 'dark'
                  ? 'images/arrive-logo.svg'
                  : 'images/arrive-logo-black.svg'
              }
              alt="Arrive"
              width="80px"
            />
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', md: desktopExpanded ? 'none' : 'block' }
          }}>
          <Link to={'/'}>
            <img src="images/arrive-logo-mini.svg" alt="Arrive" width="18px" />
          </Link>
        </Box>
      </Toolbar>
      <Divider />
      <List>
        {menuRoutes
          .filter((route) => !!route.menuName)
          .map((route) => (
            <ListItemButton
              component={Link}
              onClick={() => {
                setMobileOpen(false);
              }}
              to={route.path}
              key={route.menuName}
              reloadDocument={location.pathname === route.path}
              sx={{
                minHeight: 48,
                justifyContent: { xs: 'initial', md: desktopExpanded ? 'initial' : 'center' },
                px: 2.5
              }}>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: { xs: 3, md: desktopExpanded ? 3 : 'auto' },
                  justifyContent: 'center'
                }}>
                {route.icon}
              </ListItemIcon>
              <ListItemText
                primary={route.menuName}
                sx={{ opacity: { xs: 1, md: desktopExpanded ? 1 : 0 } }}
              />
            </ListItemButton>
          ))}
        <Divider />
        <ListItemButton onClick={handleDesktopDrawerToggle}>
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: { xs: 3, md: desktopExpanded ? 3 : 'auto' },
              justifyContent: 'center'
            }}>
            {desktopExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </ListItemIcon>
          <ListItemText
            primary="Collapse"
            sx={{ opacity: { xs: 1, md: desktopExpanded ? 1 : 0 } }}
          />
        </ListItemButton>
      </List>
    </div>
  );

  const currentMenuName = menuRoutes.find((r: Route) => r.path === location.pathname)?.menuName;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { md: desktopExpanded ? `calc(100% - ${drawerWidth}px)` : `calc(100% - 64px)` },
          ml: { md: desktopExpanded ? `${drawerWidth}px` : '64px' }
        }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}>
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h6" noWrap component="div">
              {currentMenuName ?? 'Tools'}
            </Typography>
          </Box>
          {isAuthenticated && user && (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit">
                <AccountCircleIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorMenuAppBar}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorMenuAppBar)}
                onClose={handleClose}>
                <MenuItem disabled>{user.name}</MenuItem>
                <MenuItem onClick={colorMode.toggleColorMode}>
                  <ListItemIcon>
                    {theme.palette.mode === 'light' ? (
                      <DarkModeIcon fontSize="small" />
                    ) : (
                      <LightModeIcon fontSize="small" />
                    )}
                  </ListItemIcon>
                  <ListItemText>
                    {theme.palette.mode === 'light' ? 'Dark' : 'Light'} mode
                  </ListItemText>
                </MenuItem>
                <MenuItem disabled>
                  v{process.env.REACT_APP_VERSION}{' '}
                  {process.env.REACT_APP_BUILD_NUMBER && (
                    <>({parseInt(process.env.REACT_APP_BUILD_NUMBER, 10)})</>
                  )}
                </MenuItem>
                <Divider />
                <MenuItem onClick={logout}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText>Logout</ListItemText>
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { md: desktopExpanded ? drawerWidth : '64px' },
          flexShrink: { md: 0 }
        }}
        aria-label="menu">
        <MuiDrawer
          container={document.body}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
          }}>
          {drawer}
        </MuiDrawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' }
          }}
          open={desktopExpanded}>
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { md: `calc(100% - ${drawerWidth}px)` } }}>
        <Toolbar />
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </Box>
    </Box>
  );
};

export default DrawerMenuLayout;
