import { createContext, FC, useContext, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Role, { ROLE_SCHEMA, RolesMap } from './roles.constants';
import { checkRole, grantRoles } from './roles.service';

interface State {
  roles: Map<Role, boolean>;
  hasAccess: (rolesToValidate: Role[]) => boolean;
}

const initialState = {
  roles: RolesMap
};

const AuthorizationContext = createContext<State | undefined>(undefined);

export const AuthorizationProvider: FC = ({ children }) => {
  const { user } = useAuth0();
  const [roles] = useState<Map<Role, boolean>>(
    grantRoles(initialState.roles, user?.[ROLE_SCHEMA] || [])
  );

  const value = useMemo(
    () => ({
      roles,
      hasAccess: (rolesToValidate: Role[]): boolean => checkRole(roles, rolesToValidate)
    }),
    [roles]
  );

  return <AuthorizationContext.Provider value={value}>{children}</AuthorizationContext.Provider>;
};

/**
 * Hook to check if the logged user is Authorized to access given role
 *
 * Note: if the user is an Admin this hook will always return true (authorized).
 *
 * Usage:
 *  const isAuthorized = useRole([Role.WAREHOUSE_ASSOCIATE]);
 * @param valueToValidate Role[] Array of roles required to check
 * @return a boolean with the result of the check
 */
const useRole = (valueToValidate: Role[]): boolean => {
  const context = useContext(AuthorizationContext);
  if (!context) {
    throw new Error('useRole must be used within a AuthorizationProvider');
  }
  return context.hasAccess(valueToValidate);
};

export default useRole;
