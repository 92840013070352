enum Role {
  ADMIN = 'admin',
  WAREHOUSE_MANAGER = 'warehouse-manager',
  WAREHOUSE_ASSOCIATE = 'warehouse-associate'
}

export default Role;

export const RolesConstants: Role[] = [
  Role.ADMIN,
  Role.WAREHOUSE_MANAGER,
  Role.WAREHOUSE_ASSOCIATE
];

export const RolesMap: Map<Role, boolean> = new Map(RolesConstants.map((obj) => [obj, false]));

export const ROLE_SCHEMA = 'https://schemas.arriveoutdoors.com/roles';
