import { PropsWithChildren, useEffect } from 'react';
import { client } from '@arrive/lib/graphql';
import { ApolloProvider as OriginalApolloProvider } from '@apollo/client';
import { useLocalStorage } from 'usehooks-ts';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from '@arrive/components/loader';

export function ApolloProvider({ children }: Readonly<PropsWithChildren<unknown>>) {
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  const [accessToken, setAccessToken] = useLocalStorage('access_token', '');

  useEffect(() => {
    if (isAuthenticated && user) {
      getAccessTokenSilently().then((accessToken) => {
        setAccessToken(accessToken);
      });
    }
  }, [getAccessTokenSilently, setAccessToken, isAuthenticated, user]);

  if (!accessToken) {
    return <Loader />;
  }

  return <OriginalApolloProvider client={client(accessToken)}>{children}</OriginalApolloProvider>;
}
