import { ApolloClient, InMemoryCache } from '@apollo/client';

export const client = (accessToken: string) =>
  new ApolloClient({
    uri: process.env.REACT_APP_WMS_GRAPHQL_ENDPOINT,
    cache: new InMemoryCache(),
    headers: {
      Authorization: 'Bearer ' + accessToken
    }
  });
