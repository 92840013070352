import { FC } from 'react';
import { Stack, CircularProgress, StackProps } from '@mui/material';

const Loader: FC<StackProps> = ({ ...other }: StackProps) => (
  <Stack justifyContent="center" alignItems="center" {...other}>
    <CircularProgress />
  </Stack>
);

export default Loader;
